import React from 'react';
import {
  Container, Row, Col, Card,
} from 'react-bootstrap';
import { BlogData } from '../../../data/db';
import './blog.scss';

export default function Blog() {
  return (
    <section className="blog infenox-demo">
      <div className="infenox-heading mx-auto">
        <h2 className="fs-1">{BlogData[0].name}</h2>
        <span className="infenox-text-highlight" />
      </div>
      <Container>
        <Row>
          {BlogData[0].blogs.map(({
            id, image, date, text,
          }) => (
            <Col key={id} className="col-12 col-md-6 col-lg-4 py-3">
              <Card>
                <Card.Img
                  className="card-image"
                  variant="top"
                  src={image}
                />
                <Card.Body>
                  <Card.Text className="text">{date}</Card.Text>
                  <Card.Text className="text custom-text-justify">{text}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
